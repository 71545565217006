import './index.less';

import Vue from 'vue';
import Component from 'vue-class-component';
import { IGetZSPointTableGradePage, SaveZSPointTableGradePage, zsPointTableGradeService } from '@/app/apiServices/zsPointTableGrade/public-api';
import { notThisCity_stepsList2 } from '@/core/utils/enum/notThisCity';
import { getStepsIcon } from '@/core/utils';
import storage from '@/core/utils/storage';
import { ParentScore } from '@/app/components';
@Component({
    components: { ParentScore }
})
export default class parentScoreTS extends Vue {
    formModel = new SaveZSPointTableGradePage();
    comData: any = {};    //传递给组件的数据

    stepsList = notThisCity_stepsList2;
    activeSteps = 3;


    getStepsIcon(index: number) {
        return getStepsIcon(index, this.activeSteps);
    }

    // 加载表单数据
    viewLoad(): void {
        const { childId } = storage.GET_UploadEvidenceChild();
        zsPointTableGradeService.getZSChildBaseInfoPage(childId)
            .then((res: IGetZSPointTableGradePage) => {
                // 可能为 null 不存在数据
                if (res) {
                    this.comData = res;
                }
            });
    }

    // 下一步点击
    nextOnClick(): void {
        const { parentScore }: any = this.$refs;
        if (!parentScore.checkFormModel()) {
            this.$toast({
                message: '填写内容存在错误请仔细核对！',
                position: 'top'
            });
            return;
        }

        this.formModel = parentScore.getFormModel();
        const { childId, gradeCode } = storage.GET_UploadEvidenceChild();
        this.formModel.childId = Number(childId);
        this.formModel.gradeCode = gradeCode;
        this.formModel.isSupply = true;

        // 确定下一步
        const nextFun = () => {
            const input = Object.assign(new SaveZSPointTableGradePage(), this.formModel);
            zsPointTableGradeService.saveZSChildBaseInfoPage(input)
                .then(() => {
                    this.$router.push("/uploadEvidence");
                    // this.$router.push(
                    //     `/ uploadEvidence / ${ childId } / ${ enterGardenType } / ${ gradeCode } / ${ refId }`
                    // );
                })
        }

        this.$dialog.confirm({
            title: '提示',
            message: '请确定已经填完所有项？确定点击“确定”，如未填完点击‘取消’',
        })
            .then(() => {
                nextFun();
            })
            .catch(() => {
                // on cancel
            });
    }

    back() {
        this.$router.go(-1);
    }

    mounted(): void {
        this.viewLoad();
    }
}